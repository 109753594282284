<template>
    <a-spin :spinning="spinning">
        <div class="my-md">
            <!--                    正方形LOGO-->
			<a-row style="margin-top: 20px;">
				<a-col :span="3">
					<div style="display: flex;align-items: center;justify-content: center;height: 160px;">
						<b>企业LOGO: </b>
					</div>
				</a-col>
				<a-col :span="21">
					<div class="data-upload">
					    <a-upload
					            name="profilePictureFile"
					            :action="uploadPictureUrl"
					            listType="picture-card"
					            :fileList="fileList.squareLogo"
					            :beforeUpload="beforeUpload"
					            accept="image/*"
					            :headers="uploadHeaders.squareLogo"
					            @preview="handlePreview($event,'squareLogo')"
					            @change="uploadPictureChange($event, 'squareLogo')">
					        <div v-if="fileList.squareLogo.length < 1">
					            <a-icon type="plus"/>
					            <div class="ant-upload-text">上传图片</div>
					        </div>
					    </a-upload>
					</div>
					<div class="data-tip" title="建议尺寸430*70,建议大小不要超过500k">建议上传图片尺寸为640*640，大小不超过500k</div>
				</a-col>
			</a-row>
            <!-- <a-row>
                <div class="data-row">
                    <div class="data-lable">企业LOGO:</div>
                    <div class="data-img">
                        <div class="data-upload">
                            <a-upload
                                    name="profilePictureFile"
                                    :action="uploadPictureUrl"
                                    listType="picture-card"
                                    :fileList="fileList.squareLogo"
                                    :beforeUpload="beforeUpload"
                                    accept="image/*"
                                    :headers="uploadHeaders.squareLogo"
                                    @preview="handlePreview($event,'squareLogo')"
                                    @change="uploadPictureChange($event, 'squareLogo')">
                                <div v-if="fileList.squareLogo.length < 1">
                                    <a-icon type="plus"/>
                                    <div class="ant-upload-text">上传图片</div>
                                </div>
                            </a-upload>
                        </div>
                        <div class="data-tip" title="建议尺寸430*70,建议大小不要超过500k">建议上传图片尺寸为640*640，大小不超过500k</div>
                    </div>
                </div>
            </a-row> -->
            <!--                    正方形LOGO-->
			<a-row style="margin-top: 20px;">
				<a-col :span="3">
					<div style="display: flex;align-items: center;justify-content: center;height: 160px;">
						<b>企业名称LOGO: </b>
					</div>
				</a-col>
				<a-col :span="21">
					<div class="data-upload">
					    <a-upload
					            class="oblongLogo"
					            name="profilePictureFile"
					            :action="uploadPictureUrl"
					            listType="picture-card"
					            :fileList="fileList.oblongLogo"
					            :beforeUpload="beforeUpload"
					            accept="image/*"
					            :headers="uploadHeaders.oblongLogo"
					            @preview="handlePreview($event,'oblongLogo')"
					            @change="uploadPictureChange($event, 'oblongLogo')">
					        <div v-if="fileList.oblongLogo.length < 1">
					            <a-icon type="plus"/>
					            <div class="ant-upload-text">上传图片</div>
					        </div>
					    </a-upload>
					</div>
					<div class="data-tip" title="建议尺寸430*70,建议大小不要超过500k">
					    建议上传图片尺寸为200*50，大小不超过500k
					</div>
				</a-col>
			</a-row>
            <!-- <a-row>
                <div class="data-row">
                    <div class="data-lable">企业名称LOGO:</div>
                    <div class="data-img">
                        <div class="data-upload">
                            <a-upload
                                    class="oblongLogo"
                                    name="profilePictureFile"
                                    :action="uploadPictureUrl"
                                    listType="picture-card"
                                    :fileList="fileList.oblongLogo"
                                    :beforeUpload="beforeUpload"
                                    accept="image/*"
                                    :headers="uploadHeaders.oblongLogo"
                                    @preview="handlePreview($event,'oblongLogo')"
                                    @change="uploadPictureChange($event, 'oblongLogo')">
                                <div v-if="fileList.oblongLogo.length < 1">
                                    <a-icon type="plus"/>
                                    <div class="ant-upload-text">上传图片</div>
                                </div>
                            </a-upload>
                        </div>
                        <div class="data-tip" title="建议尺寸430*70,建议大小不要超过500k">
                            建议上传图片尺寸为200*50，大小不超过500k
                        </div>
                    </div>
                </div>
            </a-row> -->
            <!--                    企业文化-->
			<a-row style="margin-top: 20px;">
				<a-col :span="3">
					<div style="display: flex;align-items: center;justify-content: center;height: 160px;">
						<b>企业文化图片: </b>
					</div>
				</a-col>
				<a-col :span="21">
					<div class="data-upload">
					    <a-upload
					            class="loginLeft"
					            name="profilePictureFile"
					            :action="uploadPictureUrl"
					            listType="picture-card"
					            :fileList="fileList.loginLeft"
					            :beforeUpload="beforeUpload"
					            accept="image/*"
					            :headers="uploadHeaders.loginLeft"
					            @preview="handlePreview($event,'loginLeft')"
					            @change="uploadPictureChange($event, 'loginLeft')">
					        <div v-if="fileList.loginLeft.length < 1">
					            <a-icon type="plus"/>
					            <div class="ant-upload-text">上传图片</div>
					        </div>
					    </a-upload>
					</div>
					<div class="data-tip" title="建议尺寸430*70,建议大小不要超过500k">
					    建议上传图片尺寸为650*500，大小不超过500k
					</div>
				</a-col>
			</a-row>
            <!-- <a-row>
                <div class="data-row">
                    <div class="data-lable">企业文化图片:</div>
                    <div class="data-img">
                        <div class="data-upload">
                            <a-upload
                                    class="loginLeft"
                                    name="profilePictureFile"
                                    :action="uploadPictureUrl"
                                    listType="picture-card"
                                    :fileList="fileList.loginLeft"
                                    :beforeUpload="beforeUpload"
                                    accept="image/*"
                                    :headers="uploadHeaders.loginLeft"
                                    @preview="handlePreview($event,'loginLeft')"
                                    @change="uploadPictureChange($event, 'loginLeft')">
                                <div v-if="fileList.loginLeft.length < 1">
                                    <a-icon type="plus"/>
                                    <div class="ant-upload-text">上传图片</div>
                                </div>
                            </a-upload>
                        </div>
                        <div class="data-tip" title="建议尺寸430*70,建议大小不要超过500k">
                            建议上传图片尺寸为650*500，大小不超过500k
                        </div>
                    </div>
                </div>
            </a-row> -->
            <a-row>
                <div style="max-width: 400px;text-align: center;margin-top: 20px">
                    <a-button :type="'primary'" @click="handleSubmit" >
                        <a-icon type="save"/>
                        <span>{{l("SaveAll")}}</span>
                    </a-button>
                </div>
            </a-row>
            <a-row>
                <a-modal :visible="previewVisible" :width="viewWidth" :bodyStyle="viewStyle" :footer="null"
                         @cancel="previewVisible=false" style="text-align: center;">
                    <img alt="example" style="height: 100%" :src="previewImage"/>
                </a-modal>
            </a-row>

        </div>
    </a-spin>

</template>

<script>
    import {AppComponentBase} from "../../../../shared/component-base";
    import {
        CompanyImagesEditDto, RemoveCompanyEditDto, TenantSettingsEditDto,
        TenantSettingsServiceProxy
    } from "../../../../shared/service-proxies";
    import {AppConsts} from "../../../../abpPro/AppConsts";
    import moment from "moment";
    import ImageUtils from "../../../../shared/utils/image-utils";

    export default {
        name: "tenant-img",
        mixins:[AppComponentBase],
        data(){
            return {
                spinning:false,
                previewVisible: false,
                //用户所有设置
                tenantSetting: new TenantSettingsEditDto(),
                //公司图片
                companyImages: new CompanyImagesEditDto(),
                //上传参数相关
                //预览的图片
                previewImage: "",
                // 上传的接口地址
                uploadPictureUrl: AppConsts.remoteServiceBaseUrl + "/Profile/UploadCompanyPicture",
                maxCompanyPictureBytesValue: AppConsts.maxCompanyPictureMb,
                // 上传的图片类型F
                uploadPictureType: '',
                // 上传时的请求头
                uploadHeaders: {
                    // 企业文化图片
                    loginLeft: {
                        Authorization: "Bearer " + abp.auth.getToken(),
                        type: 'loginLeft'
                    },
                    // 正方形Logo图片
                    squareLogo: {
                        Authorization: "Bearer " + abp.auth.getToken(),
                        type: 'squareLogo'
                    },
                    // 长方形Logo图片
                    oblongLogo: {
                        Authorization: "Bearer " + abp.auth.getToken(),
                        type: 'oblongLogo'
                    },
                },
                // 上传的文件列表
                fileList: {
                    // 企业文化图片列表
                    loginLeft: [],
                    // 正方形Logo图片列表
                    squareLogo: [],
                    // 长方形Logo图片列表
                    oblongLogo: [],

                },
                //后台拼接的图片路径
                companyImagesPath: {
                    loginLeft: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/LoginLeft/${abp.session.tenantId}/`,
                    squareLogo: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/SquareLogo/${abp.session.tenantId}/`,
                    oblongLogo: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/OblongLogo/${abp.session.tenantId}/`,

                },
                removeImageList: [],
                viewStyle: undefined,
                viewWidth: 650,
            }
        },
        created() {
            this._tenantSettingServiceProxy = new TenantSettingsServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            this.getData();
        },
        methods:{
            getData() {
                this.spinning = true;
                this._tenantSettingServiceProxy.getAllSettings()
                    .finally(() => {
                        this.spinning = false;
                    })
                    .then((res) => {
                        var d = new Date();
                        this.companyImages = res.companyImages;
                        if (this.companyImages.loginLeft) {
                            var tempUrl=AppConsts.OSSRequestUrl+'/QYB/LoginLeft/'+abp.session.tenantId+'/'+ this.companyImages.loginLeft+"?ts="+d.getTime(); 
                            this.fileList.loginLeft = [{
                                uid: -1,
                                name: this.companyImages.loginLeft,
                                status: "done",
                                url: tempUrl,
                                response: {
                                    result: {
                                        fileName: this.companyImages.loginLeft
                                    }
                                }
                            }];
                        }
                        if (this.companyImages.squareLogo) {
                            var tempUrl=AppConsts.OSSRequestUrl+'/QYB/SquareLogo/'+abp.session.tenantId+'/'+ this.companyImages.squareLogo+"?ts="+d.getTime(); 
                            console.log(tempUrl);
                            this.fileList.squareLogo = [{
                                uid: -1,
                                name: this.companyImages.squareLogo,
                                status: "done",
                                url: tempUrl,
                                response: {
                                    result: {
                                        fileName: this.companyImages.squareLogo
                                    }
                                }
                            }];
                        }
                        if (this.companyImages.oblongLogo) {
                            var tempUrl=AppConsts.OSSRequestUrl+'/QYB/OblongLogo/'+abp.session.tenantId+'/'+ this.companyImages.oblongLogo+"?ts="+d.getTime(); 
                            this.fileList.oblongLogo = [{
                                uid: -1,
                                name: this.companyImages.oblongLogo,
                                status: "done",
                                url: tempUrl,
                                response: {
                                    result: {
                                        fileName: this.companyImages.oblongLogo
                                    }
                                }
                            }];
                        }

                        if (this.companyImages.webSiteIcon) {                                        
                            //var tempUrl=AppConsts.remoteServiceBaseUrl+'/Profile/ShowImage?FileName='+ this.companyImages.webSiteIcon; 
                            var tempUrl=AppConsts.OSSRequestUrl+'/'+ this.companyImages.webSiteIcon;                           
                            this.fileList.webSiteIcon = [{
                                uid: -1,
                                name: this.companyImages.webSiteIcon,
                                status: "done",
                                //url: this.companyImagesPath.webSiteIcon + this.companyImages.webSiteIcon,
                                url:tempUrl,
                                response: {
                                    result: {
                                        fileName: this.companyImages.webSiteIcon
                                    }
                                }
                            }];
                        }
                    })
            },
            //上传相关方法
            /**
             * 上传图片
             */
            beforeUpload(file) {
                const isJPG =
                    file.type === "image/jpeg" ||
                    file.type === "image/png" ||
                    file.type === "image/gif";
                if (!isJPG) {
                    abp.message.error(this.l("OnlySupportPictureFile"));
                }
                const isLtXM = file.size / 1024 / 1024 < this.maxCompanyPictureBytesValue;
                if (!isLtXM) {
                    abp.message.error(
                        this.l(
                            "ProfilePicture_Warn_SizeLimit",
                            this.maxCompanyPictureBytesValue
                        )
                    );
                }
                return isJPG && isLtXM;
            },
            /**
             * 预览图片
             * @param file 文件
             * @returns {Promise<void>}
             */
            async handlePreview(file, type) {
                this.viewStyle = undefined;
                this.viewWidth = undefined;
                if (!file.url && !file.preview) {
                    file.preview = await ImageUtils.getBase64(file.originFileObj);
                }
                this.previewImage = file.url || file.preview;
                this.previewVisible = true;
                switch (type) {
                    //企业文化
                    case "loginLeft":
                        this.viewWidth = 650;
                        this.viewStyle = {width: "650px", height: "500px"};
                        break;
                    //     //正方形Logo
                    case "squareLogo":
                        this.viewWidth = 400;
                        this.viewStyle = {width: "400px", height: "400px"};
                        break;

                    case "oblongLogo":
                        this.viewWidth = 250;
                        this.viewStyle = {width: "250px", height: "100px"};
                        break;
                    default :
                        this.viewWidth = 520;
                        // this.viewStyle={width:"200px",height:"50px"};
                        break;
                }
            },
            /**
             * 上传图片回调  状态 "status: "done"  status: "uploading"  status: "removed"
             */
            uploadPictureChange({file, fileList}, uploadPictureType) {
                switch (uploadPictureType) {
                    case 'loginLeft':
                        if (file.status === 'done') {
                            this.companyImages.loginLeft = file.response.result.fileName;
                        } else if (file.status === 'removed') {
                            this.companyImages.loginLeft = undefined;
                            //添加移除文件
                            let dto = new RemoveCompanyEditDto();
                            dto.type = 3;
                            dto.fileName = file.response.result.fileName;
                            this.removeImageList.push(dto);
                        }
                        this.fileList.loginLeft = fileList;
                        break;
                    case 'squareLogo':
                        if (file.status === 'done') {
                            this.companyImages.squareLogo = file.response.result.fileName;
                        } else if (file.status === 'removed') {
                            this.companyImages.squareLogo = undefined;
                            //添加移除文件
                            let dto = new RemoveCompanyEditDto();
                            dto.type = 1;
                            dto.fileName = file.response.result.fileName;
                            this.removeImageList.push(dto);
                        }
                        this.fileList.squareLogo = fileList;
                        break;
                    case 'oblongLogo':
                        if (file.status === 'done') {
                            this.companyImages.oblongLogo = file.response.result.fileName;
                        } else if (file.status === 'removed') {
                            this.companyImages.oblongLogo = undefined;
                            //添加移除文件
                            let dto = new RemoveCompanyEditDto();
                            dto.type = 2;
                            dto.fileName = file.response.result.fileName;
                            this.removeImageList.push(dto);
                        }
                        this.fileList.oblongLogo = fileList;
                        break;
                }
            },
            handleSubmit() {
                    this.tenantSetting.companyImages = this.companyImages;
                    this.tenantSetting.removeCompanyImageList = this.removeImageList;
                    this.spinning = true;
                    this._tenantSettingServiceProxy
                        .updateAllSettings(this.tenantSetting)
                        .finally(() => {
                            this.spinning = false;
                        })
                        .then(res => {
                            this.$notification.success({
                                description: undefined,
                                message: this.l("SavedSuccessfully")
                            })
                        })

            },
        }
    }
</script>

<style scoped>
    .center {
        display: flex;
        align-items: center;
    }

    /* >>> .data-row {
        width: 100%;
        margin: 10px 0px;
        display: flex;
    }
    
    >>> .ant-modal-close-x {
        width: 30px;
        height: 45px;
        line-height: 30px;
    }
    
    >>> .data-lable {
        flex: 3;
        display: flex;
        font-weight: bold;
        align-items: center;
        justify-content: center;
    }
    >>> .data-img {
        flex: 21;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    >>> .data-upload {
        width: 100%;
        display: flex;
    }
    
    >>> .data-tip {
        width: 100%;
        display: flex;
        font-size: 10px;
        color: #808080e0;
    } */
    /*企业文化*/
    /* .loginLeft >>> .ant-upload-list-picture-card-container {
        width: 100%;
        height: 250px;
    }
    
    .loginLeft >>> .ant-upload-list-picture-card .ant-upload-list-item {
        width: 330px;
        height: 250px;
    } */
    
    /*长方形LOGO*/
    .oblongLogo >>> .ant-upload-list-picture-card-container {
        width: 100%;
        height: 70px;
    }
    
    .oblongLogo >>> .ant-upload-list-picture-card .ant-upload-list-item {
        width: 218px;
        height: 68px;
    }
	
	.oblongLogo >>> .ant-upload-list-item-thumbnail {
	    position: static;
		display: block;
		width: auto;
		height: 100%;
		/* -o-object-fit: cover;
		object-fit: cover; */
	}
	
	.oblongLogo >>> .ant-upload-list-item-image {
	    max-height: 100%;
		max-width: max-content;
	    margin: auto;
	}
    /*系统登录背景*/
    /* .webBackground >>> .ant-upload-list-picture-card-container {
        width: 100%;
    }
    
    .webBackground >>> .ant-upload-list-picture-card .ant-upload-list-item {
        width: 330px;
        height: 250px;
    } */
    
    /*APP登录背景*/
    /* .appBackground >>> .ant-upload-list-picture-card-container {
        width: 100%;
    }
    
    .appBackground >>> .ant-upload-list-picture-card .ant-upload-list-item {
        width: 50%;
    } */
</style>
