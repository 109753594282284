<template>
	<a-card>
		<div style="line-height:40px;font-size: 14px">
			<a-row>
				<a-col :span="3" style="text-align: center">
					<b>企业编码: </b>
				</a-col>
				<a-col :span="21">
					<b :title="getTenantCode">{{ getTenantCode }}</b>
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="3" style="text-align: center">
					<b>企业名称: </b>
				</a-col>
				<a-col :span="21">
					<b :title="getTenantName">{{ getTenantName }}</b>
				</a-col>
			</a-row>
			<a-row>
				<tenant-img ref="tenantImg"></tenant-img>
			</a-row>
		</div>
	</a-card>
</template>

<script>
import { abpService } from '../../../shared/abp';
import TenantImg from './tenant-img/tenant-img';
export default {
	name: 'tenant-info',
	components: { TenantImg },
	computed: {
		getTenantCode() {
			return abpService.abp.userInfo.tenantCode;
		},
		getTenantName() {
			return abpService.abp.userInfo.tenantName;
		},
	},
};
</script>

<style scoped></style>
